import { classNames } from "@/utils";
import React from "react";

export default function List(props) {
  return (
    <div
      role="list"
      className={classNames(
        "space-y-6 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-y-10",
        props.className
      )}
    >
      {props.children}
    </div>
  );
}
