import { classNames } from "@/utils";
import React from "react";

export default function Section({
  tag = "h2",
  isInverted = false,
  isTagReversed = false,
  is35 = false,
  ...props
}) {
  const TitleTag = isTagReversed ? "p" : tag;
  const SubtitleTag = isTagReversed ? tag : "p";

  return (
    <section
      className={classNames(
        "lg:grid lg:gap-x-12 max-w-7xl mx-auto mt-16 lg:mt-24 px-4 sm:px-6 lg:px-8",
        is35 ? "lg:grid-cols-5" : "lg:grid-cols-2",
        props.className
      )}
    >
      <div
        className={classNames(
          "sm:max-w-3xl sm:mx-auto sm:text-center lg:text-left",
          is35 ? "lg:col-span-2" : null
        )}
      >
        <SubtitleTag
          className={classNames(
            "text-sm sm:text-base font-semibold tracking-wide uppercase",
            !isInverted ? "text-green-600" : "text-[#043c2e]"
          )}
        >
          {props.subtitle}
        </SubtitleTag>
        <TitleTag
          className={classNames(
            "mt-2 text-2xl sm:text-3xl font-bold",
            !isInverted ? "text-gray-900" : "text-white"
          )}
        >
          {props.title}
        </TitleTag>
        <p
          className={classNames(
            "mt-4 sm:text-lg",
            !isInverted ? "text-gray-500" : "text-green-200"
          )}
        >
          {props.description}
        </p>
      </div>
      <div
        className={classNames("mt-8 lg:mt-0", is35 ? "lg:col-span-3" : null)}
      >
        {props.children}
      </div>
    </section>
  );
}
