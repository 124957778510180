import AboutKarolina from "@/components/AboutKarolina";
import Card from "@/components/Card";
import ContactForm from "@/components/common/ContactForm";
import Layout from "@/components/layout/Layout";
import Section from "@/components/layout/Section";
import SectionCenter from "@/components/layout/SectionCenter";
import SectionGreen from "@/components/layout/SectionGreen";
import SectionTitle from "@/components/layout/SectionTitle";
import List from "@/components/List";
import ListItem from "@/components/ListItem";
import { GatsbySeo } from "gatsby-plugin-next-seo/src/meta/gatsby-seo";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocalization } from "gatsby-theme-i18n";

export default function Index() {
  const { t } = useTranslation("common");
  const { locale } = useLocalization();

  const employerLink = locale === "pl" ? "/dla-pracodawcy" : "/kontakt";

  return (
    <Layout>
      <GatsbySeo
        titleTemplate={t("index.seo.title")}
        description={t("index.seo.description")}
      />

      <SectionTitle
        subtitle={t("index.sectionMain.subtitle")}
        title={t("index.sectionMain.title")}
        isTagReversed={true}
      >
        {t("index.sectionMain.content")}
      </SectionTitle>

      {/* Zielona sekcja */}
      <SectionGreen hasPadding={false} className="lg:!mt-32">
        <AboutKarolina>
          <p className="ml-14">{t("index.sectionAbout.p1")}</p>
          <p>{t("index.sectionAbout.p2")}</p>
          <p>{t("index.sectionAbout.p3")}</p>
          <p>{t("index.sectionAbout.p4")}</p>
        </AboutKarolina>
      </SectionGreen>

      {/* Lubimy ludzi */}
      <Section
        subtitle={t("index.sectionMission.subtitle")}
        title={t("index.sectionMission.title")}
        description={t("index.sectionMission.description")}
        className="lg:!mt-32"
      >
        <List>
          <ListItem>{t("index.sectionMission.feature1")}</ListItem>
          <ListItem>{t("index.sectionMission.feature2")}</ListItem>
          <ListItem>{t("index.sectionMission.feature3")}</ListItem>
          <ListItem>{t("index.sectionMission.feature4")}</ListItem>
        </List>
      </Section>

      {/* Zielona sekcja */}
      <SectionGreen>
        <SectionTitle
          subtitle={t("index.sectionOffer.subtitle")}
          title={t("index.sectionOffer.title")}
          tag="h2"
          isInverted={true}
        >
          {t("index.sectionOffer.description")}
        </SectionTitle>

        <SectionCenter>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-12 lg:gap-y-0 lg:gap-x-12">
            <Card
              title={t("index.sectionOffer.card1.title")}
              description={t("index.sectionOffer.card1.description")}
              link={employerLink}
              label={t("index.sectionOffer.card1.label")}
            />

            <Card
              title={t("index.sectionOffer.card2.title")}
              description={t("index.sectionOffer.card2.description")}
              link="/dla-pracownika"
              label={t("index.sectionOffer.card2.label")}
            />
          </div>
        </SectionCenter>
      </SectionGreen>

      {/* Kontakt */}
      <Section
        subtitle={t("index.sectionContact.subtitle")}
        title={t("index.sectionContact.title")}
        description={t("index.sectionContact.description")}
        is35={true}
      >
        <ContactForm />
      </Section>
    </Layout>
  );
}
