import React from "react";

export default function AboutKarolina(props) {
  return (
    <section className="relative lg:grid lg:grid-cols-2 lg:gap-x-12 max-w-7xl mx-auto mt-20 lg:mt-10 px-4 sm:px-6 lg:px-8">
      {/* O Karolinie */}
      <blockquote className="relative pt-44 lg:pt-12 pb-16 lg:pb-12 text-white">
        <svg
          className="absolute top-40 lg:top-8 left-0 h-12 w-12 text-green-900"
          fill="currentColor"
          viewBox="0 0 32 32"
          aria-hidden="true"
        >
          <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
        </svg>

        <div className="space-y-6 text-base sm:text-lg">{props.children}</div>
      </blockquote>

      {/* Zdjęcie */}
      <div className="absolute -top-12 left-1/2 lg:left-0 -translate-x-1/2 lg:transform-none lg:relative h-48 lg:h-full w-48 lg:w-full">
        <div className="absolute inset-0 lg:-bottom-24 bg-karolina bg-cover bg-center rounded-2xl" />
        <div className="absolute inset-0 lg:-bottom-24 bg-gradient-to-t from-green-600 via-transparent mix-blend-multiply rounded-2xl" />
      </div>
    </section>
  );
}
