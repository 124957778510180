import { ArrowRightIcon } from "@heroicons/react/outline";
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import React from "react";

export default function Card(props) {
  return (
    <div className="flex flex-col bg-white rounded-2xl shadow-xl">
      <div className="flex-1 relative pt-8 md:pt-16 px-6 md:px-8 md:pb-8">
        <h3 className="text-2xl sm:text-3xl font-bold text-gray-900">
          {props.title}
        </h3>
        <p className="mt-4 text-base md:text-lg font-medium text-gray-900">
          {props.description}
        </p>
      </div>
      <div className="">
        <Link
          to={props.link}
          className="flex md:justify-center md:items-center w-full px-6 md:px-8 py-6 md:py-8 rounded-br-2xl text-base md:text-lg font-medium text-green-700 hover:text-green-900"
        >
          {props.label}
          <ArrowRightIcon
            className="inline-block w-6 h-6 ml-3 align-middle"
            aria-hidden="true"
          />
        </Link>
      </div>
    </div>
  );
}
