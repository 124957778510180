import { classNames } from "@/utils";
import React from "react";

export default function SectionGreen({ hasPadding = true, ...props }) {
  return (
    <>
      <div
        className={classNames(
          "mt-16 lg:mt-24 bg-green-700 bg-top-pattern-single bg-no-repeat bg-cover",
          hasPadding ? "overflow-auto pb-16 sm:pb-24" : null,
          props.className
        )}
      >
        {props.children}
      </div>

      <div className="h-3 bg-top-pattern bg-repeat-x bg-contain" />
    </>
  );
}
