import React from "react";
import { CheckIcon } from "@heroicons/react/outline";

export default function ListItem(props) {
  const Icon = props.icon || CheckIcon;
  return (
    <div role="listitem">
      <Icon className="absolute w-6 h-6 text-green-600" aria-hidden="true" />
      <p className="pl-9 lg:text-lg font-medium leading-6 text-gray-900">
        {props.children}
      </p>
    </div>
  );
}
